import React from 'react';
import {Decimal} from 'decimal.js';

import DataDriver from 'library/DataDriver';

import './style.scss';

class PriceLabel extends React.Component {
    constructor(props) {
        super(props);

        if (!props.chargeKey){
            throw new Error('Charge key must be set');
        }

        switch (props.chargeKey) {
            case "minSpend":
                this.ddStateKey = "minDaySpend";
                break;

            case "payThresh":
                this.ddStateKey = "payThreshold";
                break;
        
            default:
                this.ddStateKey = "prices->"+props.chargeKey;
                break;
        }

        this.state = {"DD:taxRate": 0.2};
        this.state["DD:"+this.ddStateKey] = null;
    }

    static defaultProps = {
        includeTax: true       
    }

    componentDidMount(){
        this.listenerID = DataDriver.attachListenerForStateChange(this, [this.ddStateKey, "taxRate"]);
    }

    componentWillUnmount() {
        DataDriver.removeListener(this.listenerID);
    }

    render() {
        let priceStr = "";
        let price = this.state["DD:"+this.ddStateKey];
        if (price === null){
            priceStr = "-";
        }else{
            let priceNum;
            if (this.props.chargeKey === "minSpend" || this.props.chargeKey === "payThresh"){
                priceNum = new Decimal(price);
            }else{
                priceNum = new Decimal(price.currentPrice);
            }

            if (price.PriceModel == "PercOnTotal"){
                priceStr = "+"+priceNum+"%";
            }else{
                if (this.props.includeTax){
                    priceNum = priceNum.add(priceNum.mul(this.state["DD:taxRate"]));
                }

                if (priceNum.lessThan(1)){
                    priceStr = priceNum.mul(100)+"p";
                }else{
                    priceStr = "£"+priceNum;
                }
            }
        }

        if (price !== null){
            if (this.props.chargeKey === "minSpend"){
                priceStr = priceStr + " a day";
            }else if(this.props.chargeKey === "payThresh"){
                priceStr = priceStr + " a month";
            }else if(price.PriceModel == "PercOnTotal"){
                priceStr = priceStr + " on total usage fee";
            }else{
                priceStr = priceStr + " " + price.UnitLabel;
            }
        }

        return (
            <span className='priceLabel'>
                {priceStr}
                {(price !== null && price.PriceModel != "PercOnTotal") && 
                    <span className='PLTaxLabel'>
                        {this.props.includeTax ? (
                            "Incl. VAT"
                        ) : (
                            "Excl. VAT"
                        )}
                    </span>
                }
            </span>
         );
    }
}
 
export default PriceLabel;